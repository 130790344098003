import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Security',
    component: () => import('../views/Security.vue'),
    children: [
      {
        path: 'login',
        component: () => import('../views/Security/Login.vue'),
      },
      {
        path: 'password-forget',
        component: () => import('../views/Security/PasswordForget.vue'),
      },
      {
        path: 'password-reset/:token',
        component: () => import('../views/Security/PasswordReset.vue'),
      },
      {
        path: 'logout',
        component: () => import('../views/Security/Logout.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Application',
    component: () => import('../views/Application.vue'),
    children: [
      {
        path: '/research',
        component: () => import('../views/Application/Research'),
        meta: {
          headline: 'Research & Matching'
        }
      },
      {
        path: 'profile',
        component: () => import('../views/Application/Profile.vue'),
      },
      {
        path: 'api-keys',
        component: () => import('../views/Application/ApiKeys.vue'),
        meta: {
          headline: 'API-Schlüssel / Integrationen'
        }
      },
      {
        path: 'api-keys/:id',
        component: () => import('../views/Application/ApiKey.vue'),
        meta: {
          headline: 'API-Schlüssel / Integrationen'
        }
      },
      {
        path: 'customers',
        component: () => import('../views/Application/Customers.vue'),
        meta: {
          headline: 'Kunden'
        }
      },
      {
        path: 'customers/create',
        component: () => import('../views/Application/CustomerCreate.vue'),
        meta: {
          headline: 'Kunden'
        }
      },
      {
        path: 'customers/:id',
        component: () => import('../views/Application/Customer.vue'),
        meta: {
          headline: 'Kunden'
        }
      },
      {
        path: 'providers',
        component: () => import('../views/Application/Providers.vue'),
        meta: {
          headline: 'Dienstleister'
        }
      },
      {
        path: 'providers/create',
        component: () => import('../views/Application/ProviderCreate.vue'),
        meta: {
          headline: 'Dienstleister'
        }
      },
      {
        path: 'providers/:id',
        component: () => import('../views/Application/Provider.vue'),
        meta: {
          headline: 'Dienstleister'
        }
      },
      {
        path: 'offers',
        component: () => import('../views/Application/Offers.vue'),
        meta: {
          headline: 'Dienstleistungsangebote'
        }
      },
      {
        path: 'offers/create',
        component: () => import('../views/Application/OfferCreate.vue'),
        meta: {
          headline: 'Dienstleistungsangebote'
        },
        children: [
          {
            path: 'general',
            component: () => import('../views/Application/OfferCreate/General'),
          },
          {
            path: 'categories',
            component: () => import('../views/Application/OfferCreate/Categories'),
          },
          {
            path: 'organization',
            component: () => import('../views/Application/OfferCreate/Organization'),
          },
          {
            path: 'availability',
            component: () => import('../views/Application/OfferCreate/Availability'),
          }
        ]
      },
      {
        path: 'offers/:id',
        component: () => import('../views/Application/Offer.vue'),
        meta: {
          headline: 'Dienstleistungsangebote'
        }
      },
      {
        path: 'requests',
        component: () => import('../views/Application/Requests.vue'),
        meta: {
          headline: 'Anfragen'
        }
      },
      {
        path: 'requests/create',
        component: () => import('../views/Application/RequestCreate.vue'),
        meta: {
          headline: 'Anfragen'
        },
        children: [
          {
            path: 'intern',
            component: () => import('../views/Application/RequestCreate/Intern'),
          },
          {
            path: 'categories',
            component: () => import('../views/Application/RequestCreate/Categories'),
          },
          {
            path: 'organization',
            component: () => import('../views/Application/RequestCreate/Organization'),
          },
        ]
      },
      {
        path: 'requests/:id',
        component: () => import('../views/Application/Request.vue'),
        meta: {
          headline: 'Anfragen'
        }
      },
      {
        path: 'users',
        component: () => import('../views/Application/Users.vue'),
        meta: {
          headline: 'Benutzerverwaltung'
        }
      },
      {
        path: 'users/:id',
        component: () => import('../views/Application/User.vue'),
        meta: {
          headline: 'Benutzerverwaltung'
        }
      },
      {
        path: 'user-groups',
        component: () => import('../views/Application/UserGroups.vue'),
        meta: {
          headline: 'Gruppenverwaltung'
        }
      },
      {
        path: 'user-groups/:id',
        component: () => import('../views/Application/UserGroup.vue'),
        meta: {
          headline: 'Gruppenverwaltung'
        }
      },
      {
        path: 'version-and-status',
        component: () => import('../views/Application/VersionAndStatus.vue'),
        meta: {
          headline: 'Entwicklung'
        }
      },
      {
        path: 'token',
        component: () => import('../views/Application/Token.vue'),
        meta: {
          headline: 'Entwicklung'
        }
      },
      {
        path: '*',
        component: () => import('../views/Application/404.vue'),
      },
    ],
  }
]

const router = new VueRouter({
  routes
})

export default router
