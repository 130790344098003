import Vue from 'vue';
import VueResource from 'vue-resource';
import util from './util';

Vue.use(VueResource);

Vue.use({
    install(Vue) {
      Vue.prototype.$sendToApi = function (baseApiUrl, entity, callback) {
        let method = entity.hasOwnProperty('id') ? 'put' : 'post';
        let url = entity.hasOwnProperty('id') ? baseApiUrl + '/' + entity.id : baseApiUrl;

        let objectToSend = Object.assign({}, entity);
        Object.keys(objectToSend).forEach((item) => {
          if (
            typeof objectToSend[item] !== 'undefined'
            && objectToSend[item] !== null
            && objectToSend[item].hasOwnProperty('@id')
          ) {
            objectToSend[item] = objectToSend[item]['@id'];
          }

          if(Array.isArray(objectToSend[item])) {
            Object.keys(objectToSend[item]).forEach((arrayItem) => {
              if(typeof objectToSend[item] !== 'undefined' && objectToSend[item][arrayItem].hasOwnProperty('@id')) {
                objectToSend[item][arrayItem] = objectToSend[item][arrayItem]['@id'];
              }
            });
          }
        });

        this.$store.commit('incrementLoadingIndex');
        this.$http[method](url, objectToSend).then(response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            summary: 'Erfolgreich',
            severity: 'success',
            detail: 'Erfolgreich gespeichert',
            life: 3000
          });
          entity = response.data;
          callback(entity, parseInt(response.status), {});
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            life: 3000,
            summary: response.status < 500 ? 'Warnung' : 'Fehler',
            severity: response.status < 500 ? 'warn' : 'error',
            detail: parseInt(response.status) === 403 ? 'Ihr Benutzer ist nicht authorisiert den Datensatz zu bearbeiten' : 'Fehler beim Speichern'
          });
          callback(entity, parseInt(response.status), response.status !== 500 ? util.mapConstraints(response.data) : []);
        });
      };

      Vue.prototype.$getFromApiSearch = function (baseApiUrl, params, callback) {
        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](baseApiUrl, {params: params}).then(response => {
          this.$store.commit('decrementLoadingIndex');
          if (response.data['hydra:member'].length > 0) {
            callback(response.data['hydra:member'][0]);
          }
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            life: 3000,
            summary: response.status < 500 ? 'Warnung' : 'Fehler',
            severity: response.status < 500 ? 'warn' : 'error',
            detail: 'Daten konnten nicht geladen werden'
          });
          callback(null);
        });
      }

      Vue.prototype.$getFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(entity);
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            severity: response.status < 500 ? 'warn' : 'error',
            detail: 'Daten konnten nicht geladen werden'
          });
          callback(entity);
        });
      };

      Vue.prototype.$deleteFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['delete'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(null);
        }, () => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            severity: 'warn',
            detail: 'Daten konnten nicht gelöscht werden.'
          });
          callback(entity);
        });
      };
    }
  }
)

