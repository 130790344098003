import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/main.scss';

Vue.config.silent = true;

/* VueX */
import Vuex from 'vuex'
Vue.use(Vuex);
import store from "./store";

/* BrowserDetect */
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

/* PrimeVue */
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.use(ConfirmationService);

/* Extra functionality */
require('./plugins/http');
require('./plugins/api');
require('./filter');

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store: store,
}).$mount('#app')
