import Vue from 'vue';
import auth from './auth';
import VueResource from 'vue-resource';
import router from "@/router";

Vue.use(VueResource);

Vue.http.options.root = process.env.VUE_APP_ENDPOINT_URL;
Vue.http.interceptors.push(function (request, next) {
  if (
    request.url !== 'actions/password-reset'
    && request.url !== 'actions/password-forget'
    && request.url !== 'auth'
  ) {
    request.headers.set('Authorization', auth.getTokenHeader());
  }

  if(request.url.indexOf('auth') === -1 && request.method !== 'GET') {
    //request.headers.set('Content-Type',  'application/ld+json');
  }

  if (
    request.url !== 'auth'
    && request.url !== 'auth/refresh'
  ) {
    next((response) => {
      if (response.status === 401) {
        return auth.refresh().then(response => {
          auth.setAuth(response.data.token);
          auth.setRefreshToken(response.data.refresh_token);
          request.headers.set('Authorization', `Bearer ${response.data.token}`);

          return Vue.http(request).then(data => {
            return data;
          });
        }, () => {
          auth.clearAuth();
          router.push('/login');
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});
